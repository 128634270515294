'use client'

import {MoonIcon, SunIcon} from '@heroicons/react/24/solid'
import {useTranslations} from 'next-intl'
import {useTheme} from 'next-themes'
import {useCallback, useEffect, useState} from 'react'

import {buttonClasses} from '@/app/[locale]/(website)/components/ui/ButtonClasses'

export default function ThemeSwitch() {
    const t = useTranslations()
    const [mounted, setMounted] = useState(false)
    const {theme, setTheme} = useTheme()

    useEffect(() => {
        setMounted(true)
    }, [])

    const handleThemeToggle = useCallback(() => {
        setTheme(theme === 'dark' ? 'light' : 'dark')
    }, [theme, setTheme])

    if (!mounted) {
        return (
            <div className="size-9 rounded-md flex items-center justify-center">
                <div className="size-5 bg-bg-quaternary rounded animate-pulse" />
            </div>
        )
    }

    return (
        <button
            onClick={handleThemeToggle}
            className={buttonClasses({
                variant: 'navigation',
                size: 'icon',
                shape: 'rounded',
                width: 'full',
            })}
            aria-label={
                theme === 'dark' ? t('Arialabels.switchToDark') : t('Arialabels.switchToLight')
            }
            type="button"
        >
            {theme === 'dark' ? <SunIcon className="size-5" /> : <MoonIcon className="size-5" />}
        </button>
    )
}
