import {createLocalizedPathnamesNavigation} from 'next-intl/navigation'
import {defineRouting} from 'next-intl/routing'

export const routing = defineRouting({
    locales: ['en', 'nl'],
    defaultLocale: 'en',
    localePrefix: 'always',
    pathnames: {
        '/': '/',
        '/#appointments': '/#appointments',
        '/#medical': '/#medical',
        '/#finance': '/#finance',
        '/product': {
            en: '/product',
            nl: '/product',
        },
        '/product/analytics': {
            en: '/product',
            nl: '/product',
        },
        '/integrations': {
            en: '/integrations',
            nl: '/integraties',
        },
        '/integrations/[slug]': {
            en: '/integrations/[slug]',
            nl: '/integraties/[slug]',
        },
        '/security': {
            en: '/security',
            nl: '/beveiliging',
        },
        '/pricing': {
            en: '/pricing',
            nl: '/prijzen',
        },
        '/customers': {
            en: '/customers',
            nl: '/klanten',
        },
        '/customers/[slug]': {
            en: '/customers/[slug]',
            nl: '/klanten/[slug]',
        },
        '/changelog': {
            en: '/changelog',
            nl: '/updates',
        },
        '/changelog/page/[pageNum]': {
            en: '/changelog/page/[pageNum]',
            nl: '/updates/pagina/[pageNum]',
        },
        '/changelog/[slug]': {
            en: '/changelog/[slug]',
            nl: '/updates/[slug]',
        },
        '/about': {
            en: '/about',
            nl: '/over-flux',
        },
        '/blog': {
            en: '/blog',
            nl: '/blog',
        },
        '/blog/[slug]': {
            en: '/blog/[slug]',
            nl: '/blog/[slug]',
        },
        '/contact': {
            en: '/contact',
            nl: '/contact',
        },
        '/[type]/[slug]': {
            en: '/contact',
            nl: '/contact',
        },
        '/docs': {
            en: '/documentation',
            nl: '/documentatie',
        },
        '/docs/[slug]': {
            en: '/documentation/[slug]',
            nl: '/documentatie/[slug]',
        },
    },
})

export type Pathnames = keyof typeof routing.pathnames
export type Locale = (typeof routing.locales)[number]

export const {Link, getPathname, redirect, usePathname, useRouter} =
    createLocalizedPathnamesNavigation(routing)
