import {
    ArrowPathIcon,
    ChatBubbleLeftRightIcon,
    ChevronDownIcon,
    CursorArrowRaysIcon,
    RectangleGroupIcon,
    ShieldCheckIcon,
    SparklesIcon,
    SquaresPlusIcon,
} from '@heroicons/react/24/solid'
import {useTranslations} from 'next-intl'
import {useCallback, useEffect, useRef, useState} from 'react'

import {Link} from '@/i18n/routing'

import {navLinks} from './NavLinks'

// Functie voor company links
function getNavCompany() {
    return [
        {name: 'about', href: navLinks.about as '/about', icon: SparklesIcon},
        {name: 'contact', href: navLinks.contact as '/contact', icon: ChatBubbleLeftRightIcon},
    ]
}

// Functie voor product links
function getNavProducts() {
    return [
        {
            name: 'appointments',
            href: navLinks.appointments as '/#appointments',
            icon: RectangleGroupIcon,
        },
        {name: 'engagement', href: navLinks.medical as '/#medical', icon: CursorArrowRaysIcon},
        {name: 'automations', href: navLinks.finance as '/#finance', icon: ArrowPathIcon},
        {name: 'security', href: navLinks.security as '/security', icon: ShieldCheckIcon},
        {
            name: 'integrations',
            href: navLinks.integrations as '/integrations',
            icon: SquaresPlusIcon,
        },
    ]
}

export default function NavMenu({type}: {type: 'company' | 'product'}) {
    const t = useTranslations('Nav')
    const [isOpen, setIsOpen] = useState(false)
    const dropdownRef = useRef<HTMLDivElement | null>(null)
    const links = type === 'company' ? getNavCompany() : getNavProducts()

    const handleMouseEnter = useCallback(() => {
        setIsOpen(true)
    }, [])

    const handleMouseLeave = useCallback(() => {
        setIsOpen(false)
    }, [])

    const handleKeyDown = useCallback((event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            setIsOpen((prev) => !prev)
        } else if (event.key === 'Escape') {
            setIsOpen(false)
        }
    }, [])

    const handleLinkClick = useCallback(() => {
        setIsOpen(false)
    }, [])

    useEffect(() => {
        const handleBlur = (event: FocusEvent) => {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.relatedTarget as Node)
            ) {
                setIsOpen(false)
            }
        }

        const currentDropdown = dropdownRef.current

        if (currentDropdown) {
            currentDropdown.addEventListener('blur', handleBlur)
            currentDropdown.addEventListener('focusout', handleBlur)
        }

        return () => {
            if (currentDropdown) {
                currentDropdown.removeEventListener('blur', handleBlur)
                currentDropdown.removeEventListener('focusout', handleBlur)
            }
        }
    }, [dropdownRef])

    return (
        <div className="relative group">
            <div
                tabIndex={0}
                onKeyDown={handleKeyDown}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                className="flex items-center gap-x-1 text-base font-medium text-gray-900 dark:text-white/70 hover:bg-slate-300/25 hover:dark:text-white hover:dark:bg-slate-100/20 px-3 h-9 rounded-md cursor-pointer"
            >
                {type === 'company' ? t('topLevel.company') : t('topLevel.product')}
                <ChevronDownIcon
                    className={`size-4 flex-none text-slate-900/35 dark:text-slate-300 transition-transform duration-500 ${isOpen ? 'rotate-180' : ''}`}
                    aria-hidden="true"
                />
            </div>
            <div
                ref={dropdownRef}
                className={`absolute top-full left-0 z-50 mt-1 w-max dark:bg-zinc-950 max-w-xl overflow-hidden rounded-xl backdrop-blur-3xl bg-white border border-slate-200/45 shadow-lg transition-all duration-300 transform ${isOpen ? 'opacity-100 visible scale-100' : 'opacity-0 invisible scale-90'}`}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                <ul className="flex flex-col p-2 gap-2">
                    {links.map((item) => (
                        <li
                            key={item.name}
                            className={`rounded-lg leading-6 hover:bg-slate-200/55 hover:dark:bg-slate-500/45`}
                        >
                            <Link
                                href={item.href}
                                className="relative flex gap-x-2 p-2.5 rounded-lg focus-visible:bg-slate-200/55 focus-visible:dark:bg-slate-500/45"
                                tabIndex={0}
                                onKeyDown={handleKeyDown}
                                onClick={handleLinkClick}
                            >
                                <div className="flex size-4 flex-none items-start">
                                    <item.icon
                                        className="size-6 text-gray-400 hover:text-gray-900 dark:text-slate-500"
                                        aria-hidden="true"
                                    />
                                </div>
                                <div className="flex-auto">
                                    <span className="block text-base font-semibold text-gray-900 dark:text-slate-300">
                                        {t(`${item.name}.title`)}
                                    </span>
                                    <span className="text-gray-500 text-sm dark:text-slate-500">
                                        {t(`${item.name}.description`)}
                                    </span>
                                </div>
                            </Link>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    )
}
