'use client'
import {useTranslations} from 'use-intl'
export default function Logo({className}: {className?: string}) {
    const t = useTranslations()
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 185 185"
            width="100%"
            height="100%"
            aria-label={t('Arialabels.fluxLogoType')}
            className={className}
        >
            <defs>
                <style>
                    {`
              .cls-1{isolation:isolate;}
              .cls-2{fill:url(#linear-gradient);}
              .cls-3{fill:#033770;mix-blend-mode:soft-light;}
              .cls-4{fill:url(#linear-gradient-2);}
            `}
                </style>
                <linearGradient
                    id="linear-gradient"
                    x1="864.26"
                    y1="541"
                    x2="996.12"
                    y2="541"
                    gradientTransform="translate(-101.25 837.56) rotate(-45)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0" stopColor="#273347" />
                    <stop offset="0.84" stopColor="#0059ba" />
                </linearGradient>
                <linearGradient
                    id="linear-gradient-2"
                    x1="900.58"
                    y1="541"
                    x2="1032.43"
                    y2="541"
                    gradientTransform="translate(-101.25 837.56) rotate(-45)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.29" stopColor="#02ceff" />
                    <stop offset="0.36" stopColor="#05c0ff" />
                    <stop offset="0.53" stopColor="#0b9fff" />
                    <stop offset="0.68" stopColor="#0f8bff" />
                    <stop offset="0.78" stopColor="#1084ff" />
                    <stop offset="1" stopColor="#1084ff" />
                </linearGradient>
            </defs>
            <title>flux logo</title>
            <g className="cls-1">
                <g id="Layer_1" data-name="Layer 1">
                    <path
                        className="cls-2"
                        d="M894.4,516a25,25,0,0,0,0,50h41v41a25,25,0,1,0,50,0V516Z"
                        transform="translate(-869.4 -450)"
                    />
                    <polygon
                        className="cls-3"
                        points="66 116 48.22 66 116 66 116 132.01 66 116"
                    />
                    <path
                        className="cls-4"
                        d="M985.4,516V475a25,25,0,0,0-50,0v91h91a25,25,0,0,0,0-50Z"
                        transform="translate(-869.4 -450)"
                    />
                </g>
            </g>
        </svg>
    )
}
