import {SanityPageType} from 'app/[locale]/(website)/types/sanity'

export default function getTypeForPath(path: string): SanityPageType | null {
    if (path.includes('/changelog') || path.includes('/updates')) {
        return 'changelog'
    }

    if (path.includes('/customer') || path.includes('/klanten')) {
        return 'customer'
    }

    if (path.includes('/blog')) {
        return 'blog'
    }

    if (path.includes('/integration') || path.includes('/integraties')) {
        return 'integration'
    }

    if (
        path.includes('/documentation') ||
        path.includes('/documentatie') ||
        path.includes('/docs')
    ) {
        return 'doc'
    }

    return null
}
