'use client'

import {ChevronDownIcon} from '@heroicons/react/24/solid'
import {useParams} from 'next/navigation'
import {useCallback, useEffect, useRef, useState, useTransition} from 'react'

import {Locale, Pathnames, usePathname, useRouter} from '@/i18n/routing'
import {getLocalizedSlugs} from '@/sanity/queries/getAllLocalizedSlugs'

import {LocalizedSlug} from '../helpers/getAlternates'
import getTypeForPath from '../helpers/getTypeForPath'

type Params = Record<string, string | string[] | undefined>

type RouteObject = {
    pathname: Pathnames
    params?: Params
}

interface Props {
    defaultValue: string
    label: string
    children?: React.ReactNode
}

export default function LocaleSwitcherSelect({defaultValue, label, children}: Props) {
    const router = useRouter()
    const [, startTransition] = useTransition()
    const pathname = usePathname()
    const params = useParams()
    const slug = params?.slug as string | undefined
    const locale = params?.locale as string | undefined
    const type = getTypeForPath(pathname)

    const [localizedSlugs, setLocalizedSlugs] = useState<LocalizedSlug[] | null>(null)
    const [selectedLocale, setSelectedLocale] = useState(defaultValue)
    const [isOpen, setIsOpen] = useState(false)
    const dropdownRef = useRef<HTMLDivElement | null>(null)

    const getTranslatedSlug = useCallback(async () => {
        if (slug && type) {
            try {
                const fetchedSlugs = await getLocalizedSlugs(type, slug)
                setLocalizedSlugs(fetchedSlugs)
            } catch (error) {
                console.error('Error fetching localized slugs:', error)
                setLocalizedSlugs(null)
            }
        }
    }, [type, slug])

    useEffect(() => {
        if (locale && slug) {
            getTranslatedSlug()
        }
    }, [locale, slug, getTranslatedSlug])

    const onOptionClick = useCallback(
        (nextLocale: Locale) => {
            if (nextLocale === selectedLocale) return

            setSelectedLocale(nextLocale)

            startTransition(() => {
                const routeObject: RouteObject = {
                    pathname: pathname as Pathnames,
                    params: {...params},
                }

                if (slug && localizedSlugs) {
                    const matchingSlug = localizedSlugs.find(
                        (slugObj) => slugObj.language === nextLocale,
                    )?.slug
                    if (matchingSlug) {
                        routeObject.params = {...params, slug: matchingSlug}
                    }
                }

                router.replace(routeObject as any, {locale: nextLocale})
            })
        },
        [localizedSlugs, params, pathname, router, selectedLocale, slug],
    )

    const handleKeyDown = useCallback(
        (event: React.KeyboardEvent<HTMLLIElement>, nextLocale: Locale, isActive: boolean) => {
            if (!isActive && (event.key === 'Enter' || event.key === ' ')) {
                event.preventDefault()
                onOptionClick(nextLocale)
            }
        },
        [onOptionClick],
    )

    const handleItemClick = useCallback(
        (nextLocale: Locale, isActive: boolean) => (event: React.MouseEvent<HTMLLIElement>) => {
            event.preventDefault()
            if (!isActive) {
                onOptionClick(nextLocale)
            }
        },
        [onOptionClick],
    )

    const createKeyDownHandler = useCallback(
        (nextLocale: Locale, isActive: boolean) => {
            return (event: React.KeyboardEvent<HTMLLIElement>) =>
                handleKeyDown(event, nextLocale, isActive)
        },
        [handleKeyDown],
    )

    const handleMouseEnter = useCallback(() => {
        setIsOpen(true)
    }, [])

    const handleMouseLeave = useCallback(() => {
        setIsOpen(false)
    }, [])

    const handleDropdownKeyDown = useCallback((e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === 'Enter' || e.key === ' ') {
            e.preventDefault()
            setIsOpen((prev) => !prev)
        }
    }, [])

    useEffect(() => {
        const handleBlur = (event: FocusEvent) => {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.relatedTarget as Node)
            ) {
                setIsOpen(false)
            }
        }

        const currentDropdown = dropdownRef.current

        if (currentDropdown) {
            currentDropdown.addEventListener('blur', handleBlur)
            currentDropdown.addEventListener('focusout', handleBlur)
        }

        return () => {
            if (currentDropdown) {
                currentDropdown.removeEventListener('blur', handleBlur)
                currentDropdown.removeEventListener('focusout', handleBlur)
            }
        }
    }, [dropdownRef])

    return (
        <div
            className="relative group"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <div
                tabIndex={0}
                className="flex items-center gap-x-1 text-base font-medium text-gray-900 dark:text-white/70 hover:bg-slate-300/25 hover:dark:text-white hover:dark:bg-slate-100/20 px-3 h-9 rounded-md cursor-pointer"
                onKeyDown={handleDropdownKeyDown}
            >
                {label}
                <ChevronDownIcon
                    className={`size-4 flex-none text-slate-900 dark:text-slate-300 transition-transform duration-500 ${isOpen ? 'rotate-180' : ''}`}
                    aria-hidden="true"
                />
            </div>

            <div
                ref={dropdownRef}
                className={`absolute top-full z-50 mt-1 w-max dark:bg-zinc-950 max-w-xl overflow-hidden rounded-xl backdrop-blur-3xl bg-white border border-slate-200/45 shadow-lg transition-all duration-500 ease-out ${isOpen ? 'opacity-100 visible scale-100' : 'opacity-0 invisible scale-90'}`}
            >
                <ul className="flex flex-col p-2 gap-2" role="menu">
                    {children &&
                        (children as React.ReactNode[]).map((child) => {
                            const optionElement = child as React.ReactElement<{
                                value: Locale
                                children: React.ReactNode
                            }>

                            const isActive = optionElement.props.value === selectedLocale

                            return (
                                <li
                                    key={optionElement.props.value}
                                    className={`list-item group rounded-lg leading-6 text-primary dark:text-dark-primary ${
                                        isActive
                                            ? 'cursor-default opacity-10 pointer-events-none'
                                            : 'cursor-pointer hover:bg-slate-200/55 hover:dark:bg-slate-500/45'
                                    } p-2 focus-visible:bg-slate-200/55 focus-visible:dark:bg-slate-500/45`}
                                    tabIndex={isActive ? -1 : 0}
                                    role="menuitem"
                                    onClick={handleItemClick(optionElement.props.value, isActive)}
                                    onKeyDown={createKeyDownHandler(
                                        optionElement.props.value,
                                        isActive,
                                    )}
                                    aria-disabled={isActive}
                                >
                                    {optionElement.props.children}
                                </li>
                            )
                        })}
                </ul>
            </div>
        </div>
    )
}
